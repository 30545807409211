.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.floating-contact-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 25px;
  border: black;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .8);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  transition: transform 0.3s, background-color 0.3s;
  animation: float 2s infinite;
}

.floating-contact-button:hover {
  background-color: #FFA400;
  transform: translateY(-5px);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.countdown-timer {
  text-align: center;
  margin-top: 20px;
}

.countdown-timer span {
  display: inline-block;
  margin: 0 10px;
}
.bg-custom-orange {
  background-color: #FFA900;
}
.bg-custom-orange-text {
 color: #FFA300;
}
